import {
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      id="About"
      className="bg-blue flex-col gap-10 items-center  justify-center mt-16 pt-16  px-16 "
    >
      <div className=" flex justify-between gap-10 items-start md:flex-col ">
        <div className="flex flex-col gap-4 items-start w-1/4 md:w-full md:items-center">
          <a href="#top">
            <img
              className="h-[55px] w-auto cursor-pointer "
              src="/assets/logo.png"
              alt="logo"
            />
          </a>
          <Link to="/privacy-policy" className="text-white">
            Privacy Policy
          </Link>
        </div>

        <div className=" flex flex-col gap-6 items-center w-1/4 md:w-full md:items-center">
          <h2 className="text-bg text-2xl ">Socials</h2>

          <ul className="flex justify-center gap-6 items-center w-full  text-xl">
            <li className="bg-transparent w-12 h-12 rounded-full flex justify-center items-center hover:bg-bg duration-300  group cursor-pointer hover:scale-110">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-bg/80 text-2xl  "
                href="https://www.facebook.com/MrMoamedSalama?mibextid=ZbWKwL
"
              >
                <FontAwesomeIcon
                  className="group-hover:scale-110 group-hover:text-blue duration-300"
                  icon={faFacebookF}
                />{" "}
              </a>
            </li>
            <li className="bg-transparent w-12 h-12 rounded-full flex justify-center items-center hover:bg-bg duration-300  group cursor-pointer hover:scale-110">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-bg/80 text-2xl  "
                href="https://www.facebook.com/MrMoamedSalama?mibextid=ZbWKwL
"
              >
                <FontAwesomeIcon
                  className="group-hover:scale-110 group-hover:text-blue duration-300"
                  icon={faInstagram}
                />{" "}
              </a>
            </li>

            <li className="bg-transparent w-12 h-12 rounded-full flex justify-center items-center hover:bg-bg duration-300  group cursor-pointer hover:scale-110">
              <a
                target="_blank"
                rel="noreferrer"
                className="text-bg/80 text-2xl  "
                href="https://www.facebook.com/MrMoamedSalama?mibextid=ZbWKwL"
              >
                <FontAwesomeIcon
                  className="group-hover:scale-110 group-hover:text-blue duration-300"
                  icon={faYoutube}
                />{" "}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-8 ">
        <hr className="border-bg border-2" />
        <div className="flex justify-between md:flex-col  gap-5 pt-9 items-center">
          <div className="flex justify-center gap-5 ">
            <a
              className="w-36 h-auto"
              href="https://play.google.com/store/apps/details?id=com.msmatrix.app"
            >
              <img src="/assets/googleplay.png" alt="googleplay" />
            </a>

            <img
              className="w-36 h-auto"
              src="/assets/appstore.png"
              alt="appstore"
            />
          </div>
          <p className="text-bg text-sm ">
            ©2023 Mr. Mohamed Salama. All Rights Reserved.
          </p>
        </div>
      </div>
      <p className="text-center p-6 mt-8 text-white border-t-[4px] border-white">
        Created By{" "}
        <a
          className="font-bold "
          target="_blank"
          href="https://natlix.com/"
          rel="noreferrer"
        >
          Natlix{" "}
        </a>
        | © 2023 All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;
